<template>
  <div>
    <!-- Brand -->
    <SettingTable
      header="Brand"
      v-model:items="brands"
      @addItem="addBrand"
      @deleteItem="deleteBrand"
      @onRowReorder="onBrandRowReorder"
      @onRowUpdate="onBrandRowUpdate"
    />

    <SettingTable
      header="Priority"
      v-model:items="priorities"
      @addItem="addPriority"
      @deleteItem="deletePriority"
      @onRowReorder="onPriorityRowReorder"
      @onRowUpdate="onPriorityRowUpdate"
    />

    <SettingTable
      header="Status"
      v-model:items="statuses"
      @addItem="addStatus"
      @deleteItem="deleteStatus"
      @onRowReorder="onStatusRowReorder"
      @onRowUpdate="onStatusRowUpdate"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";

import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import SettingTable from "@/components/SettingTable";

import BrandService from "@/services/brand_service";
import ProductService from "@/services/product_service";

export default defineComponent({
  components: {
    SettingTable,
  },

  setup() {
    onMounted(() => {
      loadBrands();
      loadPriorities();
      loadStatuses();
    });

    const confirm = useConfirm();
    const toast = useToast();

    // Brand
    const brands = ref();

    const loadBrands = () => {
      BrandService.list().then(({ data }) => (brands.value = data.brands));
    };

    const addBrand = (name, note) => {
      const payload = {
        brand: {
          name: name,
          note: note,
        },
      };

      BrandService.create(payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Brand created",
            life: 3000,
          });
          loadBrands();
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Brand not created",
            life: 3000,
          });
        });
    };

    const deleteBrand = (index) => {
      let record = brands.value[index];
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          BrandService.delete(record.id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Brand deleted",
                life: 3000,
              });
              loadBrands();
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Brand not deleted",
                life: 3000,
              });
            });
        },
      });
    };

    const onBrandRowReorder = (record, previousRecord) => {
      const payload = {
        id: record.id,
        previous_id: previousRecord?.id,
      };

      BrandService.resort(payload)
        .then(({ data }) => {
          toast.add({
            severity: "success",
            summary: "Brand resorted",
            life: 3000,
          });

          brands.value = data.brands;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Brand not resorted",
            life: 3000,
          });
        });
    };

    const onBrandRowUpdate = (record, field) => {
      const payload = {};
      payload[field] = record[field];

      BrandService.update(record.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Brand update",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Brand not updated",
            life: 3000,
          });

          loadBrands();
        });
    };

    // Priority
    const priorities = ref();

    const loadPriorities = () => {
      ProductService.listPriorities().then(
        ({ data }) => (priorities.value = data.priorities)
      );
    };

    const addPriority = (name, note) => {
      const payload = {
        priority: {
          name: name,
          note: note,
        },
      };

      ProductService.createPriority(payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Priority created",
            life: 3000,
          });
          loadPriorities();
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Priority not created",
            life: 3000,
          });
        });
    };

    const deletePriority = (index) => {
      let record = priorities.value[index];
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          ProductService.deletePriority(record.id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Priority deleted",
                life: 3000,
              });
              loadPriorities();
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Priority not deleted",
                life: 3000,
              });
            });
        },
      });
    };

    const onPriorityRowReorder = (record, previousRecord) => {
      const payload = {
        id: record.id,
        previous_id: previousRecord?.id,
      };

      ProductService.resortPriority(payload)
        .then(({ data }) => {
          toast.add({
            severity: "success",
            summary: "Priority resorted",
            life: 3000,
          });

          priorities.value = data.priorities;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Priority not resorted",
            life: 3000,
          });
        });
    };

    const onPriorityRowUpdate = (record, field) => {
      const payload = {};
      payload[field] = record[field];

      ProductService.updatePriority(record.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Priority update",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Priority not updated",
            life: 3000,
          });

          loadPriorities();
        });
    };

    // Status
    const statuses = ref();

    const loadStatuses = () => {
      ProductService.listStatuses().then(
        ({ data }) => (statuses.value = data.statuses)
      );
    };

    const addStatus = (name, note) => {
      const payload = {
        status: {
          name: name,
          note: note,
        },
      };

      ProductService.createStatus(payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Status created",
            life: 3000,
          });
          loadStatuses();
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Status not created",
            life: 3000,
          });
        });
    };

    const deleteStatus = (index) => {
      let record = statuses.value[index];
      confirm.require({
        message: `Are you sure you want to delete?`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Delete",
        rejectLabel: "Cancel",
        accept: () => {
          ProductService.deleteStatus(record.id)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Status deleted",
                life: 3000,
              });
              loadStatuses();
            })
            .catch((e) => {
              console.error(e);
              toast.add({
                severity: "error",
                summary: "Status not deleted",
                life: 3000,
              });
            });
        },
      });
    };

    const onStatusRowReorder = (record, previousRecord) => {
      const payload = {
        id: record.id,
        previous_id: previousRecord?.id,
      };

      ProductService.resortStatus(payload)
        .then(({ data }) => {
          toast.add({
            severity: "success",
            summary: "Status resorted",
            life: 3000,
          });

          statuses.value = data.statuses;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Status not resorted",
            life: 3000,
          });
        });
    };

    const onStatusRowUpdate = (record, field) => {
      const payload = {};
      payload[field] = record[field];

      ProductService.updateStatus(record.id, payload)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Status update",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "warn",
            summary: "Status not updated",
            life: 3000,
          });

          loadStatuses();
        });
    };

    return {
      brands,
      addBrand,
      deleteBrand,
      onBrandRowReorder,
      onBrandRowUpdate,

      priorities,
      addPriority,
      deletePriority,
      onPriorityRowReorder,
      onPriorityRowUpdate,

      statuses,
      addStatus,
      deleteStatus,
      onStatusRowReorder,
      onStatusRowUpdate,
    };
  },
});
</script>

<style scoped>
.addBrand {
  font-size: medium;
  font-weight: 500;
}
</style>
